import React from 'react';
import styled from '@emotion/styled';

const Outer = styled.div`
  margin-right: 0.5rem;
  background-color: #1a202c;
  transform: skew(-11deg);
  color: #fff;
  border-bottom: 2px solid transparent;

  &:hover,
  &.active {
    border-bottom-color: #fff;
  }
`;

const Inner = styled.div`
  margin: 0 auto;
  padding: 0.275rem 0.875rem;
  transform: skew(11deg);
  color: inherit;

  @media (min-width: 768px) {
    padding: 0.875rem 1.25rem;
  }
`;

const SkewBox = ({ children, className }) => (
  <Outer className={className}>
    <Inner>{children}</Inner>
  </Outer>
);

export default SkewBox;
