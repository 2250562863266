import React from 'react';

const CowIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.002 512.002"
    xmlSpace="preserve"
    className={className}
  >
    <path
      style={{ fill: '#A3603E' }}
      d="M452.269,90.208H179.216l5.205,2.893l-23.329-8.985c-13.951-5.657-29.865-3.319-41.606,6.092
	l-25.599-8.533c-16.392-3.831-33.432,2.987-42.665,17.066l-39.14,70.593c-7.364,12.961-2.824,29.447,10.137,36.811
	c4.062,2.312,8.661,3.524,13.337,3.524h49.201c5.145,0,10.009,2.321,13.252,6.314l60.328,74.313
	c2.466,3.046,3.814,6.843,3.814,10.76v130.468h34.132l22.706-114.503c1.69-7.867,8.644-13.491,16.69-13.491H426.67l25.599,25.599
	l-17.066,102.395h34.132l34.132-110.928l-11.997-29.985c-3.251-8.14-3.251-17.219,0-25.36l11.997-29.985v-93.862
	C503.466,113.127,480.547,90.208,452.269,90.208z"
    />
    <g>
      <path
        style={{ fill: '#8F5436' }}
        d="M161.245,295.663c0.589,1.741,0.896,3.558,0.904,5.393v130.468h34.132l22.706-114.503
		c1.69-7.867,8.644-13.491,16.69-13.491H426.67l25.599,25.599l-17.066,102.395h34.132l34.132-110.928l-11.997-29.985
		c-3.251-8.14-3.251-17.219,0-25.36l11.997-29.985v-93.862c0-8.738-2.236-17.33-6.511-24.95
		C425.535,235.267,252.599,279.928,161.245,295.663z"
      />
      <path
        style={{ fill: '#8F5436' }}
        d="M64.73,98.032C56.675,88.97,45.59,83.159,33.559,81.675H8.558c0,17.996,3.601,31.418,17.066,38.654
		l22.04,8.985L64.73,98.032z"
      />
    </g>
    <g>
      <rect
        x="68.262"
        y="124.339"
        style={{ fill: '#75462E' }}
        width="17.066"
        height="17.066"
      />
      <path
        style={{ fill: '#75462E' }}
        d="M474.736,218.065c-17.979-2.227-31.358-17.68-31-35.787v-6.417c0.009-9.6-7.773-17.382-17.373-17.39
		c-0.009,0-0.009,0-0.017,0h-16.742c-4.71,0-8.533-3.823-8.533-8.533l0,0c0-4.71,3.823-8.533,8.533-8.533h16.742
		c19.028,0,34.456,15.427,34.456,34.456v6.988c-0.247,8.738,5.845,16.383,14.421,18.09c9.309,1.459,18.047-4.906,19.506-14.216
		c0.137-0.879,0.205-1.766,0.205-2.654v-34.132c0-4.71,3.823-8.533,8.533-8.533l0,0c4.71,0,8.533,3.823,8.533,8.533v34.132
		c0,18.849-15.274,34.132-34.123,34.14C476.826,218.21,475.777,218.158,474.736,218.065z"
      />
    </g>
    <g>
      <path
        style={{ fill: '#8F5436' }}
        d="M153.617,132.872h-17.066c-4.71,0-8.533-3.823-8.533-8.533c0-4.71,3.823-8.533,8.533-8.533h16.494
		c5.401-0.794,12.868-3.789,19.984-17.919l-15.001-5.777c-10.973-4.514-23.525-2.79-32.877,4.514
		c-3.507,3.149-8.908,2.85-12.057-0.657c-3.149-3.507-2.85-8.908,0.666-12.057c14.139-11.571,33.458-14.514,50.395-7.68
		l23.337,8.977c4.394,1.69,6.596,6.63,4.898,11.025c0,0,0,0,0,0.009c-8.686,22.552-21.017,34.541-37.707,36.64
		C154.325,132.906,153.967,132.898,153.617,132.872z"
      />
      <path
        style={{ fill: '#8F5436' }}
        d="M452.269,337.662c-2.261,0-4.437-0.896-6.033-2.5l-44.738-44.738
		c-11.238-11.17-17.535-26.384-17.492-42.229v-12.927c0-4.71,3.823-8.533,8.533-8.533s8.533,3.823,8.533,8.533v12.927
		c-0.034,11.323,4.463,22.186,12.492,30.164l44.738,44.738c3.328,3.336,3.328,8.738,0,12.066
		C456.697,336.757,454.53,337.662,452.269,337.662z"
      />
    </g>
    <rect
      x="68.262"
      y="124.339"
      width="17.066"
      height="17.066"
      fill="#1A202C"
    />
    <path
      fill="#1A202C"
      d="M452.269,81.675H179.216v0.273l-15.061-5.794c-15.206-6.135-32.451-4.429-46.155,4.565l-21.418-7.142
	c-13.328-3.661-27.595-1.092-38.808,6.988c-7.134-4.872-15.581-7.458-24.216-7.424H8.558c-4.71,0-8.533,3.823-8.533,8.533
	c0,16.102,2.449,35.906,21.964,46.376l2.56,1.195l-19.89,35.838c-9.71,17.057-3.746,38.756,13.311,48.467
	c5.359,3.046,11.417,4.65,17.586,4.65h49.201c2.568,0,5,1.16,6.622,3.157l60.328,74.313c1.237,1.519,1.911,3.422,1.911,5.376
	v130.477c0,4.71,3.823,8.533,8.533,8.533h34.132c4.07,0,7.569-2.884,8.362-6.878l22.689-114.341
	c0.845-3.934,4.326-6.741,8.345-6.741h187.451l20.001,19.993l-16.341,98.026c-0.776,4.65,2.364,9.045,7.005,9.821
	c0.461,0.077,0.939,0.119,1.408,0.119h34.132c3.746,0,7.057-2.44,8.157-6.024l34.132-110.928c0.572-1.869,0.495-3.874-0.23-5.683
	l-12.006-29.985c-2.449-6.101-2.449-12.91,0-19.011l12.006-29.985c0.401-1.007,0.606-2.091,0.606-3.174v-93.862
	C511.956,108.434,485.24,81.709,452.269,81.675z M29.668,112.811c-7.944-4.693-12.654-13.388-12.236-22.604h16.127
	c4.002-0.017,7.953,0.879,11.554,2.628c-0.435,0.606-0.956,1.135-1.357,1.766l-10.922,19.694L29.668,112.811z M494.933,167.004
	v17.066c0,9.429-7.637,17.066-17.066,17.066s-17.066-7.637-17.066-17.066v-8.533c0-18.849-15.282-34.132-34.132-34.132h-25.599
	v17.066h25.599c9.429,0,17.066,7.637,17.066,17.066v8.533c0,18.849,15.282,34.132,34.132,34.132
	c6.007-0.034,11.903-1.672,17.066-4.744v20.163l-11.391,28.474c-4.079,10.171-4.079,21.511,0,31.683l10.879,27.186l-31.384,102.028
	H445.28l15.359-92.454c0.452-2.722-0.435-5.487-2.381-7.441l-44.695-44.738c-8.029-7.978-12.526-18.841-12.492-30.164v-12.927
	h-17.066v12.927c-0.043,15.846,6.255,31.06,17.492,42.229l4.565,4.574H235.678c-12.1,0.06-22.527,8.533-25.053,20.368
	l-21.349,107.626h-18.593V301.047c-0.009-5.871-2.031-11.562-5.726-16.127l-60.328-74.322c-4.847-5.999-12.159-9.48-19.873-9.463
	H35.556c-10.197,0.009-18.465-8.251-18.465-18.448c0-3.234,0.845-6.417,2.457-9.216l38.876-70.149
	c7.151-10.76,20.104-16.11,32.766-13.55l25.599,8.533c2.893,0.939,6.067,0.29,8.362-1.707c9.352-7.304,21.904-9.028,32.877-4.514
	l15.001,5.777c-7.116,14.165-14.583,17.16-19.984,17.919h-16.494v17.066h17.066c0.358,0,0.717-0.017,1.067-0.068
	c15.999-1.988,27.945-13.217,36.598-34.038h260.987c23.551,0.026,42.639,19.114,42.665,42.665V167.004z"
    />
  </svg>
);

export default CowIcon;
