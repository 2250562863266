import fonts from './fonts';
import galleryLibStyles from './gallery-lib.js';
import animations from './animations';

export const globalStyles = `
  ${fonts}
  ${animations}
  ${galleryLibStyles}

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    position: relative;
    font-family: "Open Sans", sans-serif;
  }

  img {
    margin-bottom: 0;
  }
`;
