import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { Box, Flex, Text, Stack } from '@chakra-ui/core';
import BlockContent from '@sanity/block-content-to-react';
import styled from '@emotion/styled';

import Container from './container';
import CowIcon from './icons/cow';
import useIntersection from '../lib/intersection';
import InstagramIcon from './icons/instagram';
import FacebookIcon from './icons/facebook';
import YoutubeIcon from './icons/youtube';

const Cow = styled(CowIcon)`
  position: absolute;
  top: -75px;
  right: -100px;
  height: 90px;

  &.show {
    animation-name: cow;
    animation-delay: 1.5s;
    animation-duration: 5s;

    @media (min-width: 48em) {
      animation-name: cowDesktop;
      animation-delay: 1.5s;
      animation-duration: 8s;
    }
  }
`;

const Calf = styled(CowIcon)`
  height: 45px;
`;

const Calves = styled(Flex)`
  position: absolute;
  top: -35px;
  right: -100px;
  width: 100px;
  justify-content: space-between;
  z-index: 1;

  &.show {
    animation-name: calves;
    animation-delay: 1.5s;
    animation-duration: 5s;

    @media (min-width: 48em) {
      animation-name: calvesDesktop;
      animation-delay: 1.5s;
      animation-duration: 8s;
    }
  }
`;

const query = graphql`
  query FooterQuery {
    sanitySiteConfig {
      facebookUrl
      instagramUrl
      youtubeUrl
      footerNavigation {
        title
        slug {
          current
        }
      }
      footerText: _rawFooterText
    }
  }
`;

const Footer = () => {
  const [ref, entry] = useIntersection({});

  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          sanitySiteConfig: {
            facebookUrl,
            instagramUrl,
            youtubeUrl,
            footerNavigation,
            footerText,
          },
        } = data;
        return (
          <Box paddingTop={['48', '56']} color="white" overflow="hidden">
            <Box ref={ref} position="relative" background="#1A202C">
              <Cow className={entry.isIntersecting ? 'show' : 'hide'} />
              <Calves className={entry.isIntersecting ? 'show' : 'hide'}>
                <Calf />
                <Calf />
              </Calves>
              <Container paddingY={[12, 16]}>
                <Flex flexDirection="column" alignItems="center">
                  <Stack textAlign="center" marginBottom="12" spacing="4">
                    <Text>Folgt uns auch auf</Text>
                    <Flex
                      width="100%"
                      alignItems="center"
                      justifyContent="space-around"
                    >
                      <Box width="50px" marginX="4">
                        <a
                          href={instagramUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <InstagramIcon />
                        </a>
                      </Box>
                      <Box width="50px" marginX="4">
                        <a
                          href={facebookUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookIcon />
                        </a>
                      </Box>
                      <Box width="50px" marginX="4">
                        <a
                          href={youtubeUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <YoutubeIcon />
                        </a>
                      </Box>
                    </Flex>
                  </Stack>
                  {footerNavigation ? (
                    <Flex
                      alignItems="center"
                      justifyContent={['space-around']}
                      marginBottom={[12]}
                    >
                      {footerNavigation.map(item => (
                        <Link key={item.slug.current} to={item.slug.current}>
                          <Text textDecoration="underline" marginX="4">
                            {item.title}
                          </Text>
                        </Link>
                      ))}
                    </Flex>
                  ) : null}
                  {footerText ? <BlockContent blocks={footerText} /> : null}
                </Flex>
              </Container>
            </Box>
          </Box>
        );
      }}
    />
  );
};

export default Footer;
