import React from 'react';
import { Box } from '@chakra-ui/core';

const Container = ({ children, ...props }) => (
  <Box maxWidth="1190px" marginX="auto" paddingX={['5']} {...props}>
    {children}
  </Box>
);

export default Container;
