import React from 'react';
import { Global } from '@emotion/core';
import { CSSReset, ThemeProvider } from '@chakra-ui/core';

import Header from './header';
import Footer from './footer';
import { customTheme } from '../theme';
import { globalStyles } from '../theme/styles';

// breakpoints: ["30em", "48em", "62em", "80em"],

const Layout = ({ children }) => (
  <ThemeProvider theme={customTheme}>
    <CSSReset />
    <Global styles={globalStyles} />
    <Header />
    <main>{children}</main>
    <Footer />
  </ThemeProvider>
);

export default Layout;
