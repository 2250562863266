import { useEffect, useRef, useState } from 'react';

export default ({
  root = null,
  rootMargin,
  threshold = 0,
  once = false,
  callback,
}) => {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);

  const isClient = typeof window === 'object';

  const observer = isClient
    ? useRef(
        new window.IntersectionObserver(
          ([entry], observer) => {
            callback ? callback(entry) : updateEntry(entry);
            if (once && entry.isIntersecting) {
              observer.disconnect();
            }
          },
          {
            root,
            rootMargin,
            threshold,
          },
        ),
      )
    : null;

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry];
};
