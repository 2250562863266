import React from 'react';
import { Location } from '@reach/router';
import { graphql, Link, StaticQuery } from 'gatsby';
import { Box, Flex, Text } from '@chakra-ui/core';

import Grid from './grid';
import Container from './container';
import LogoIcon from './icons/logo';
import SkewBox from './SkewBox';

const query = graphql`
  query HeaderNaviQuery {
    sanitySiteConfig {
      mainNavigation {
        title
        titleMenu
        slug {
          current
        }
      }
    }
  }
`;

const Header = () => (
  <StaticQuery
    query={query}
    render={data => {
      const {
        sanitySiteConfig: { mainNavigation },
      } = data;
      return (
        <Location>
          {({ location }) => (
            <header>
              <Box
                paddingTop={['12']}
                paddingBottom={['6']}
                position="absolute"
                top="5px"
                right="0"
                left="0"
              >
                <Container>
                  <Grid gridRowGap="6">
                    {mainNavigation ? (
                      <Flex
                        gridRow={['2', '1']}
                        gridColumn={['1 / -1', '1 / 10']}
                        alignItems="center"
                        justifyContent={['flex-start', 'flex-end']}
                        color="white"
                        paddingX={[1, 0]}
                      >
                        {mainNavigation.map(item => (
                          <Link to={item.slug.current} key={item.slug.current}>
                            <SkewBox
                              className={
                                item.slug.current === location.pathname
                                  ? 'active'
                                  : ''
                              }
                            >
                              <Text
                                fontSize={['sm', 'lg', 'xl']}
                                color="inherit"
                              >
                                {item.titleMenu}
                              </Text>
                            </SkewBox>
                          </Link>
                        ))}
                      </Flex>
                    ) : null}
                    <Flex
                      gridRow={['1']}
                      gridColumn={['1 / -1', '10 / -1']}
                      alignItems="center"
                      justifyContent={['center', 'flex-end']}
                    >
                      <Box
                        width={['100px', '120px', '200px']}
                        height={['100px', '120px', '200px']}
                      >
                        <Link to="/">
                          <LogoIcon />
                        </Link>
                      </Box>
                    </Flex>
                  </Grid>
                </Container>
              </Box>
            </header>
          )}
        </Location>
      );
    }}
  />
);

export default Header;
