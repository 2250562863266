import { theme } from '@chakra-ui/core';

const customTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    body: 'Open Sans, sans-serif',
    heading: 'Open Sans, sans-serif',
  },
};

export { customTheme };
