import React from 'react';
import { Grid as ChakraGrid } from '@chakra-ui/core';

const Grid = ({ children, ...props }) => (
  <ChakraGrid
    gridTemplateColumns="repeat(12, 1fr)"
    gridColumnGap={[2, 8, 24]}
    gridRowGap={[8]}
    {...props}
  >
    {children}
  </ChakraGrid>
);

export default Grid;
